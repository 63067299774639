import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { SquaresPlusIcon } from "@heroicons/react/20/solid";
import { getDatabase, set, ref } from "firebase/database";

function HandleAssistance() {
	const params = useLocation();
	const navigate = useNavigate();
	const aula = params.state.data;
	const { user } = useAuth();

	const [data, setData] = useState({
		aula: aula,
		modo: "",
		fecha: new Date(),
		usuario: user.email
	});

	const [errorMessage, setErrorMessage] = useState("");

	const handleChangeText = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const generateID = () => {
		let newDate = new Date();
		const date = newDate
			.toLocaleDateString("en-US", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit"
			})
			.replace(/[^0-9]/g, "");
		const time = newDate.getTime().toString();
		return date + time;
	};

	const handleSave = () => {
		if (data.modo === "") {
			setErrorMessage("Debe seleccionar ENTRADA o SALIDA");
			return;
		}
		const db = getDatabase();
		const id = generateID();

		try {
			set(ref(db, "picajes/" + user.uid + "/" + id), {
				aula: data.aula,
				modo: data.modo,
				fecha: data.fecha.toLocaleString(),
				usuario: data.usuario
			})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					console.log("Finalized");
				});
		} catch (error) {
			console.log(error);
		}

		navigate("/");
	};

	return (
		<div className="mt-20 w-full">
			<div className="bg-white w-auto h-screen m-5 flex flex-col">
				<p className="p-3 text-center font-bold">Estás en el aula {aula}</p>
				<div className="text-center">
					<div className="inline-flex rounded-lg shadow-sm mx-auto" role="group">
						<button
							onClick={() => handleChangeText("modo", "entrada")}
							type="button"
							className={`${
								data.modo === "entrada" ? "border-blue-300 border-2" : "text-gray-900 bg-white"
							} px-4 py-2 text-sm font-medium border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
						>
							Entrada
						</button>
						<button
							onClick={() => handleChangeText("modo", "salida")}
							type="button"
							className={`${
								data.modo === "salida" ? "border-blue-300 border-2" : "text-gray-900 bg-white"
							} px-4 py-2 text-sm font-medium border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
						>
							Salida
						</button>
					</div>
				</div>
				<p className="text-center my-4">{data.fecha.toLocaleString("es-ES", { dateStyle: "medium", timeStyle: "medium" })}</p>
				<button onClick={() => handleSave()} className="text-center p-2 rounded self-center bg-blue-500 text-white flex items-center">
					<SquaresPlusIcon height={15} width={15} className="mr-2" />
					Guardar
				</button>
				{errorMessage && <p className="text-center my-8 text-sm bg-red-400 w-fit mx-auto py-2 px-3 border-1 border-red-600 text-white border-r-4">{errorMessage}</p>}
			</div>
		</div>
	);
}

export default HandleAssistance;
