import React, { useEffect, useState } from "react";
import { onValue, ref, getDatabase, query, limitToFirst } from "firebase/database";
import { functions } from "../firebase";
import { DocumentChartBarIcon } from "@heroicons/react/24/outline";

const PAGE_SIZE = 20; // Number of records per page

function UserDetail() {
	const email = window.location.pathname.substring(1);
	const [user, setUser] = useState();
	const [picajes, setPicajes] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);

	const getUserInfo = async () => {
		const getUser = functions.httpsCallable("getUserByEmail");
		await getUser(email).then((value) => {
			setUser(value.data);
			const db = getDatabase();
			const picajesRef = query(ref(db, "picajes/" + value.data.uid), limitToFirst(100));

			onValue(picajesRef, (snapshot) => {
				const data = snapshot.val();

				if (data) {
					setPicajes(Object.values(data).sort((a, b) => a.fecha - b.fecha));
				}
			});
		});
	};

	const getModoStyle = (modo) => {
		if (modo.toUpperCase() === "SALIDA") {
			return "inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10";
		} else {
			return "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20";
		}
	};
	const exportCSV = () => {
		let csvContent = ["Fecha", "Clase", "Modo"].join(";") + "\r\n";
		picajes.forEach((picaje) => {
			const row = [picaje.fecha, picaje.aula, picaje.modo];
			csvContent += row.join(";") + "\r\n";
		});

		const BOM = "\uFEFF";
		const blob = new Blob([BOM + csvContent]);
		const url = window.URL.createObjectURL(blob);
		const linkElement = document.createElement("a");
		linkElement.href = url;
		linkElement.setAttribute("download", user.email + ".csv");
		linkElement.click();
	};

	useEffect(() => {
		getUserInfo();
	}, [user?.uid]);

	// Pagination Logic
	const totalPages = Math.ceil(picajes.length / PAGE_SIZE);
	const paginatedPicajes = picajes.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);

	return (
		<div className="my-20 mx-2 lg:mx-10 w-full lg:w-[90%] rounded-lg shadow-lg bg-white">
			{user ? (
				<div>
					<h1 className="text-base lg:text-2xl font-bold m-5">{user.displayName}</h1>
					<div className="flex justify-end mr-20">
						<button onClick={exportCSV} className="flex items-center">
							<DocumentChartBarIcon height={20} width={20} />
							CSV
						</button>
					</div>
					{picajes.length ? (
						<div className="mx-3 rounded-lg justify-center flex flex-col items-center">
							<table className="table-auto w-full text-left mx-3">
								<thead>
									<tr>
										<th>Fecha</th>
										<th>Aula</th>
										<th>Modo</th>
									</tr>
								</thead>
								<tbody>
									{paginatedPicajes.map((picaje) => {
										return (
											<tr key={picaje.fecha} className="border-b">
												<td>{picaje.fecha}</td>
												<td>{picaje.aula}</td>
												<td className={getModoStyle(picaje.modo)}>{picaje.modo.toUpperCase()}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							<div className="flex justify-between w-fit items-center gap-4 mt-4 mb-2">
								<button onClick={() => setCurrentPage(Math.max(0, currentPage - 1))} disabled={currentPage === 0} className="px-4 py-2 bg-blue-200 disabled:bg-gray-300 rounded-md">
									Anterior
								</button>
								<span>
									Página {currentPage + 1} de {totalPages}
								</span>
								<button
									onClick={() => setCurrentPage(Math.min(totalPages - 1, currentPage + 1))}
									disabled={currentPage >= totalPages - 1}
									className="px-4 py-2 bg-blue-200 disabled:bg-gray-300 rounded-md"
								>
									Siguiente
								</button>
							</div>
						</div>
					) : (
						<p className="ml-3 font-bold text-red-500">No existen registros</p>
					)}
				</div>
			) : (
				<p>Cargando...</p>
			)}
		</div>
	);
}

export default UserDetail;
