import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { firestore } from "../firebase";
import { useNavigate } from "react-router-dom";
import { PlusIcon, QrCodeIcon } from "@heroicons/react/20/solid";
import { onValue, ref, getDatabase, query, limitToFirst, orderByChild } from "firebase/database";

const PAGE_SIZE = 12; // Number of records per page

export function Home() {
	const { user } = useAuth();
	const navigate = useNavigate();

	const handleNavigate = (route) => {
		navigate(route);
	};

	const [currentUser, setCurrentUser] = useState({
		email: "",
		nombre: "",
		apellidos: "",
		rol: ""
	});

	const [users, setUsers] = useState([]);
	const [picajes, setPicajes] = useState([]);
	const [currentPage, setCurrentPage] = useState(0); // Pagination state

	useEffect(() => {
		getInfo();
	}, [currentUser.rol]);

	const getInfo = () => {
		try {
			firestore
				.collection("users")
				.where("email", "==", user.email)
				.limit(1)
				.onSnapshot((snapshot) => {
					snapshot.forEach((item) => {
						const info = item.data();
						if (info) {
							setCurrentUser(info);
						}
					});
				});
		} catch (error) {
			console.log(error);
		}
		if (currentUser) {
			if (currentUser.rol === "administrador") {
				firestore.collection("users").onSnapshot((querySnapshot) => {
					const list = [];
					querySnapshot.forEach((item) => {
						const info = item.data();
						list.push(info);
					});
					setUsers(list);
				});
			} else {
				const db = getDatabase();
				const picajesRef = query(ref(db, "picajes/" + user.uid), limitToFirst(1000));

				onValue(picajesRef, (snapshot) => {
					const data = snapshot.val();
					if (data) {
						setPicajes(Object.values(data).reverse());
					}
				});
			}
		}
	};

	// Pagination Logic
	const totalPages = Math.ceil(picajes.length / PAGE_SIZE);
	const paginatedPicajes = picajes.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);

	return (
		<div className="w-full max-w mx-auto lg:max-w-7xl m-auto text-black">
			{currentUser.rol === "administrador" ? (
				<div className="p-2">
					<div className="h-screen mt-20">
						<button onClick={() => handleNavigate("/newteacher")} className="bg-white text-xs lg:text-base shadow-lg p-2 mb-4 rounded-lg flex items-center">
							<PlusIcon height={20} width={20} className="" />
							Crear usuario
						</button>
						<table className="min-w-full rounded-lg text-xs lg:text-base text-left bg-white">
							<thead>
								<tr>
									<th className="border p-2">Email</th>
									<th className="border p-2">Nombre</th>
									<th className="border p-2">Rol</th>
								</tr>
							</thead>
							<tbody>
								{users.map((usuario) => {
									const name = usuario.apellidos.split(" ")[0] + ", " + usuario.nombre;
									return (
										<tr className="cursor-pointer" key={usuario.email} onClick={() => handleNavigate(`/${usuario.email}`)}>
											<td className="border p-2 flex-0">{usuario.email}</td>
											<td className="border p-2">{name}</td>
											<td className="border capitalize p-2">{usuario.rol}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<div className="p-3 h-[100vh] flex flex-col items-start">
					<div className="min-h-fit flex-1 mt-16 relative bg-white rounded shadow-lg w-full">
						<h1 className="font-bold lg:text-xl p-2 relative">¡Bienvenido {currentUser.nombre}!</h1>
						<button onClick={() => handleNavigate("/scan")} className="bg-blue-500 text-white text-sm p-3 m-3 rounded-lg flex items-center">
							<QrCodeIcon height={15} width={15} className="mr-2" />
							Picar asistencia
						</button>
						<h1 className="font-bold lg:text-xl mx-3 relative">Mis picajes</h1>
						<div className="overflow-x-auto w-full flex justify-center">
							<table className="table-auto rounded-lg w-full text-xs lg:text-base text-center mx-3 bg-white">
								<thead>
									<tr>
										<th className="border py-2 text-sm">Clase</th>
										<th className="border py-2 text-sm">Modo</th>
										<th className="border py-2 text-sm">Fecha</th>
									</tr>
								</thead>
								<tbody>
									{paginatedPicajes.map((picaje) => {
										const [fecha, hora] = picaje.fecha.split(", ");
										const [dia, mes, año] = fecha.split("/");
										const fechaObj = new Date(año, mes - 1, dia, ...hora.split(":").map(Number));
										return (
											<tr key={picaje.fecha} className="cursor-pointer">
												<td className="border p-2 capitalize">{picaje.aula}</td>
												<td className="border p-2">
													<span
														className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset capitalize ${
															picaje.modo === "salida" ? "bg-red-50 text-red-700 ring-red-600/10" : "bg-green-50 text-green-700 ring-green-600/10"
														} `}
													>
														{picaje.modo}
													</span>
												</td>
												<td className="border p-2 text-sm">{fechaObj.toLocaleString("es-ES", { dateStyle: "medium", timeStyle: "short" })}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						<div className="flex justify-between mt-4 w-fit mx-auto gap-4 mb-4 items-center">
							<button onClick={() => setCurrentPage(Math.max(0, currentPage - 1))} disabled={currentPage === 0} className="px-4 py-2 bg-blue-200 disabled:bg-gray-300 rounded-md">
								Anterior
							</button>
							<span>
								Página {currentPage + 1} de {totalPages}
							</span>
							<button
								onClick={() => setCurrentPage(Math.min(totalPages - 1, currentPage + 1))}
								disabled={currentPage >= totalPages - 1}
								className="px-4 py-2 disabled:bg-gray-300 bg-blue-200 rounded-md"
							>
								Siguiente
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
